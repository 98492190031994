<template>
  <v-container fluid grid-list-xs pa-0>
    <v-layout row>
      <v-flex sm12 md7>
        <v-card class="elevation-12">
          <v-toolbar dense dark color="secondary">
            <v-toolbar-title>Create new Caregiver training</v-toolbar-title>
          </v-toolbar>
          <v-progress-linear style="position: absolute" v-show="loading" :indeterminate="true" class="ma-0"></v-progress-linear>
          <v-card-text>
            <v-layout row wrap v-if="isAdminOrBilling">
              <v-flex xs12 sm6>
                <select-user v-model="userId" @change="changedUser()"></select-user>
              </v-flex>
              <v-flex xs12 sm6>
                <v-select box hid :disabled="loading" :items="clients" v-model="clientId" label="Client" prepend-icon="fa-user" item-text="clientName" item-value="clientId" @input="clientSelected">
                  <template slot="item" slot-scope="{ item }">
                    <v-list-tile-avatar>
                      <img :style="!item.active ? 'opacity: 0.5' : ''" :src="`images/${item.gender ? item.gender.toLowerCase() : 'nogender'}.png`" />
                    </v-list-tile-avatar>
                    <v-list-tile-content>
                      <v-list-tile-title :class="{ 'grey--text text--lighten-1': !item.active }">{{ item.clientName }}</v-list-tile-title>
                      <v-list-tile-sub-title :class="{ 'grey--text text--lighten-1': !item.active }"
                        >{{ item.dob | moment("utc", "MM/DD/YYYY") }} | Code: {{ item.clientCode || "N/A" }}
                      </v-list-tile-sub-title>
                    </v-list-tile-content>
                  </template>
                </v-select>
              </v-flex>
            </v-layout>
            <div v-if="!isAdminOrBilling">
              <v-chip label class="mb-3 ml-5" :color="unitsAvailable > 0 ? 'success' : 'error'" text-color="white">
                <v-avatar>
                  <v-icon>fa-star</v-icon>
                </v-avatar>
                Units available: {{ unitsAvailable.toLocaleString() }}
              </v-chip>
            </div>
            <v-form ref="form" autocomplete="off" v-model="validForm">
              <v-subheader>{{ activeDate | moment("LL") }}</v-subheader>
              <v-layout row wrap>
                <v-flex sm6>
                  <v-text-field
                    box
                    required
                    label="Session date"
                    v-model="dateSelected"
                    return-masked-value
                    prepend-icon="fa-calendar"
                    mask="##/##/####"
                    data-vv-name="dateSelected"
                    :rules="errors.collect('dateSelected')"
                    v-validate="'required|date_format:MM/dd/yyyy'"
                  />
                </v-flex>
                <v-flex sm6 v-if="isAdminOrBilling">
                  <v-select box :disabled="loading" label="Service" v-model="service" required :items="services" prepend-icon="fa-map-marker-alt" :rules="[required]" />
                </v-flex>
                <v-flex sm12>
                  <v-select box :disabled="loading" label="Pos" v-model="session.pos" required :items="posEnum" prepend-icon="fa-map-marker-alt" :rules="[required]"></v-select>
                </v-flex>
              </v-layout>
              <v-layout row wrap>
                <v-flex sm12 md6>
                  <v-text-field box :disabled="loading" label="From" v-model="timeStart" type="time" required prepend-icon="fa-clock" :rules="[required]"></v-text-field>
                </v-flex>
                <v-flex sm12 md6>
                  <v-text-field box :disabled="loading" label="To" v-model="timeEnd" type="time" required prepend-icon="fa-clock" :rules="[required]"></v-text-field>
                </v-flex>
              </v-layout>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn :disabled="loading" flat @click="close">Cancel</v-btn>
            <v-btn :disabled="loading || !validForm" :loading="loading" color="primary" @click="submit">Create</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import masterTableApi from "@/services/api/MasterTablesServices";
import sessionServicesApi from "@/services/api/SessionServices";
import userApi from "@/services/api/UserServices";

export default {
  components: {
    SelectUser: () => import(/* webpackChunkName: "SelectUser" */ "@/components/shared/SelectUser")
  },

  data() {
    return {
      loading: false,
      validForm: false,
      required: (value) => !!value || "This field is required.",
      posEnum: [],
      timeStart: null,
      timeEnd: null,
      session: {
        pos: null,
        behaviorAnalysisCode: {
          hcpcs: null
        }
      },
      dateSelected: null,
      unitsAvailable: 0,
      service: null,
      userId: null,
      clients: [],
      clientId: null,
      activeClientId: 0,
      services: []
    };
  },

  computed: {
    // activeClientId() {
    //   return this.$store.getters.activeClientId;
    // },
    activeDate() {
      return this.$store.getters.activeDate;
    },
    user() {
      return this.$store.getters.user;
    },
    isLead() {
      return this.user.rol2 === "analyst";
    },
    isAssistant() {
      return this.user.rol2 === "assistant";
    },
    isAdminOrBilling() {
      return this.user.rol2 === "admin" || this.user.rol2 === "billing";
    }
  },

  async mounted() {
    if (this.isLead) {
      this.service = "97156";
    } else if (this.isAssistant) {
      this.service = "97156HN";
    } else {
      this.services.push("97156");
      this.services.push("97156HN");
      // this.services.push("H2019");
      this.service = "97156";
    }
    this.activeClientId = this.$store.getters.activeClientId;
    this.dateSelected = this.$moment(this.activeDate).format("MM/DD/YYYY");
    this.posEnum = await masterTableApi.getPosCodes();
    this.unitsAvailable = await sessionServicesApi.getUnitsAvailable(this.activeClientId, this.$moment(this.activeDate).format("YYYY-MM-DD"), this.service, "");
  },

  methods: {
    close() {
      if (!this.isAdminOrBilling) {
        this.$router.push("/clients/sessions_details");
      } else {
        window.location.reload();
      }
    },

    async submit() {
      try {
        this.loading = true;
        this.session.userId = this.isAdminOrBilling ? this.userId : -1;
        this.session.clientId = this.activeClientId;
        this.session.sessionType = 2; //caregiver training
        this.session.behaviorAnalysisCode.hcpcs = this.service;
        let dateFixed = this.dateSelected;
        let start = this.$moment(dateFixed + " " + this.timeStart);
        let end = this.$moment(dateFixed + " " + this.timeEnd);
        this.session.sessionStart = start;
        this.session.sessionEnd = end;
        console.log(this.session);
        await sessionServicesApi.addSession(this.session);
        this.$toast.success("Session created sucessful");
        this.close();
      } catch (error) {
        this.$toast.error(error.message || error);
      } finally {
        this.loading = false;
      }
    },
    async clientSelected(id) {
      this.$store.commit("SET_ACTIVE_CLIENT", id);
      this.activeClientId = id;
    },

    async changedUser() {
      await this.loadUserClients();
    },

    async loadUserClients() {
      this.clients = [];
      this.loading = true;
      try {
        console.log(this.userId);
        this.clients = await userApi.loadUserClients(this.userId);
      } catch (error) {
        this.$toast.error(error);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
